<template>
<div id="page-usuarios">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12>
        <base-material-card color="primary" icon="library_books" title="Administrar Usuarios" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="usuarios.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontro ningun registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getUsuario(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteUsuario(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="indigo" @click.native="resetPassword(props.row)" title="Resetear Contraseña" v-tippy>
                      <v-icon>lock</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
            </div>
          </v-card-text>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>

  <v-dialog v-model="modal" max-width="45%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Usuario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateUsuario() : saveUsuario()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre de Usuario para iniciar sesión" :disabled="update" v-model="model.name" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre Completo" v-model="model.nombre" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Email" v-model="model.email" :rules="[rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="8" lg="8">
                <v-autocomplete v-model="model.perfil" :items="perfiles" :hide-no-data="true" :hide-selected="true" item-text="nombre" return-object label="Perfil" :rules="[requiredObject]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="8" lg="8">
                <v-autocomplete v-model="model.sucursal" :items="sucursales" :hide-no-data="true" :hide-selected="true" item-text="nombre" return-object label="Sucursal" :rules="[requiredObject]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Cargo" v-model="model.cargo" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Teléfono" v-model="model.telefono" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateUsuario() : saveUsuario()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  mounted: function() {
    if (!this.verificaPermiso('t.utilerias.usuarios')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }

    this.get_sucursales();
    this.get_perfiles();
    this.getUsuarios();
  },
  created: function() {


  },
  data() {
    return {
      loadingValoresSubtipo: false,
      fab: [],
      name: "datagrid",
      sucursales: [],
      perfiles: [],
      columns: [
        "name",
        "nombre",
        "perfil.nombre",
        "sucursal.nombre",
        "estatus",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["nombre", "name", "perfil.nombre", "sucursal.nombre", "estatus"],
        sortable: ["nombre", "name", "perfil.nombre", "sucursal.nombre", "estatus"],

        headings: {

          nombre: "Nombre",
          name: "Usuario",
          'perfil.nombre': "Perfil",
          'sucursal.nombre': "Sucursal",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns: {
          estatus: [{
              id: "Activo",
              text: "Activo"
            },
            {
              id: "Inactivo",
              text: "Inactivo"
            }            
          ]
        }
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: "/admin"
        },
        {
          text: "Usuarios",
          disabled: true,
          href: ""
        }
      ],
      title: "",

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        name: "",
        password: "",
        type: "user",
        nombre: "",
        perfil: "",
        sucursal: "",
        roles: "",
        estatus: true,
        cargo: "",
        telefono: "",
        ultimo_movimiento:null
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if(value)
            return pattern.test(value) || "Correo electrónico no valido";
          else 
            return true;
        },
        rfclen: v =>
          (v && v.length >= 9) || "RFC debe tener al menos 9 caractéres"
      },
      search: "",
      usuarios: {
        selected: [],
        headers: [{
            text: "Nombre de Usuario",
            value: "nombre"
          },
          {
            text: "Email",
            value: "email"
          },
          {
            text: "Perfil",
            value: "perfil"
          },
          {
            text: "Estatus",
            value: "estatus",
            align: "center"
          },
          {
            text: "Operaciones",
            value: "operaciones",
            align: "center"
          }
        ],
        items: [{
          perfil: 0
        }]
      },
      db_config:{headers:{Authorization: "Basic YWRtaW46YWRtaW4xMjMq"}} //Usuario admin

    };
  },
  methods: {

    openModal: function(type) {
      if (type === 1) {
        this.title = "Nuevo Usuario";
        this.update = false;
        this.model.name = "";
        this.model.nombre = "";
        this.model.email = "";
        this.model.sucursal = {
          _id: '0',
          nombre: 'TODAS'
        };
        this.model.perfil = "";
        this.model.estatus = "Activo";
        this.model.telefono = "";
        this.model.cargo = "";

      } else {
        this.title = "Actualizar Usuario";
        this.update = true;
      }

      this.modal = true;
    },
    get_sucursales: function() {

      let data = {
        "selector": {
          "type": "sucursales",
          "estatus": {
            "$eq": "Activo"
          }
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      axios.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          this.sucursales = [];
          if (response.data.docs.length > 0)
            this.sucursales = response.data.docs;
          this.sucursales.unshift({
            _id: '0',
            nombre: 'TODAS'
          });
        })

        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las sucursales.",
            footer: ""
          });
        });
    },
    get_perfiles: function() {

      let data = {
        "selector": {
          "type": "configuracion",
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.perfiles = response.data.docs[0].perfiles != undefined ? response.data.docs[0].perfiles : [];
          else
            this.perfiles = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las sucursales.",
            footer: ""
          });
        });
    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
    getUsuarios: function() {

      let where = {
        type: "user"
      };
      let data = {
        selector: where
      }

      window.dialogLoader.show('Espere un momento por favor..');
      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.usuarios.items = response.data.docs;
          else
            this.usuarios.items = [];
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los usuarios.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },

    getUsuario: function(id_usuario) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + "/_users/" + id_usuario)
        .then(response => {
          this.model = response.data;          
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener al usuario",
            footer: ""
          });
        });
    },
    validaUnicos: async function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
        await self.validaName().then(result => {}).catch(err => {
          duplicados.push("Usuario");
        });

        if (duplicados.length > 0)
          return reject(duplicados);
        else
          return resolve(true);

      });

    },

    validaName: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.name != null && self.model.name != "") {
          var busca = encodeURI(escape(self.model.name.toString().trim().toLowerCase()));
          window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/_users/_design/vistas/_view/name?key=\"' + busca + '\"')
            .then(response => {

              if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                if (self.model._id != "") { //Esta editando
                  var filter = response.data.rows.find(r => {
                    return self.model._id != r.id;
                  });
                  if (filter) {
                    return reject(false);
                  }
                } else { //Es nuevo
                  return reject(false);
                }
              }
              return resolve(true);
            })
            .catch(err => {
              return reject(false);
            });
        } else
          return resolve(true);
      });
    },
    saveUsuario: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {

          let data = {
            name: this.model.name,
            password: this.model.name,
            type: "user",
            nombre: this.model.nombre,
            email: this.model.email,
            roles: ['sistema'],
            estatus: this.model.estatus,
            sucursal: this.model.sucursal,
            perfil: this.model.perfil,
            telefono: this.model.telefono,
            cargo: this.model.cargo,
            ultimo_movimiento: 0
          };
          
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/_users/org.couchdb.user:' + this.model.name, data)
            .then(response => {
              this.modal = false;
              window.dialogLoader.showSnackbar('El usuario se creó correctamente..', {
                color: 'success'
              });
              this.getUsuarios();
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: error.response.data.username,
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        }).catch(err => {
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },
    updateUsuario: function() {

      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {
          if (this.model.password == '') {
            delete this.model.password;
          }
          this.model.roles = ['sistema'];
          let data = this.model;

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El usuario se actualizó correctamente..', {
                color: 'success'
              });
              this.getUsuarios();
            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al actualizar el usuario..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        }).catch(err => {
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },
    deleteUsuario: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          
          window.axios
          .get(process.env.VUE_APP_COUCHDB_URL + "/_users/" + _id)
          .then(response => {
            response.data["_deleted"] = true;

            window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + _id + '?conflicts=true', response.data, this.db_config)
            .then(responseDel => {
              window.dialogLoader.showSnackbar('El usuario se eliminó correctamente..', {
                color: 'success'
              });
              this.getUsuarios();
            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al eliminar el usuario..', {
                color: 'error'
              });
            });
            
          }).catch(error => {

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener al usuario",
              footer: ""
            });
          });

          /*window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + "/_users/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El usuario se eliminó correctamente..', {
                color: 'success'
              });
              this.getUsuarios();
            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al eliminar el usuario..', {
                color: 'error'
              });
            });*/

        }

      });
    },
    resetPassword: function(row) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Realmente desea resetear la contraseña?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        console.log(result);
        if (result.isConfirmed) {
          row.password = row.name
          row.roles = ['sistema'];
          let data = row;
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + row._id, data)
            .then(response => {
              window.dialogLoader.showSnackbar('La contraseña se actualizó correctamente..', {
                color: 'success'
              });
              this.getUsuarios();
            })
            .catch(error => {
              console.log(error);
              window.dialogLoader.showSnackbar('Ocurrio un error al actualizar la contraseña..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        }
      });
    },

  }
};
</script>
